

jQuery(function($) {

  console.log('PRIVET');

  //  MASKS ON

  $('input[type=tel]').mask('+0(000) 000-0000');

  //  MASKS OFF

  $("form").submit(function (e) {
    var formData = new FormData($(this)[0]);
    var th = $(this);
    $.ajax({
      url: 'scripts/mail.php',
      type: "POST",
      data: formData,
      async: false,
      success: function (msg) {
        console.log('YEP');
        $('.main__form').addClass('owned');
        $('.main__form span').html('Спасибо <br>за заявку!');
        setTimeout(function () {
          // Done Functions
          th.trigger("reset");
        }, 1000);
      },
      error: function (msg) {
        alert('Ошибка!');
      },
      cache: false,
      contentType: false,
      processData: false
    });
    e.preventDefault();
  });

});